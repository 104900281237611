import components from "./feed.components";

export default {
  methods: {
    excludeUpdatePaths: function(path) {
      const exclude = ["/post/create"];
      return path && exclude.includes(path);
    },
    excludeUpdateQuery: function() {
      const exclude = ["feed", "admin", "gallery"];

      for (let key of exclude) {
        if (this.$route.query[key]) {
          return true;
        }
      }

      return false;
    },
    mustBeUpdate: function(to, from) {
      if (this.excludeUpdatePaths(to.path)) {
        return false;
      }

      if (this.excludeUpdatePaths(from.path)) {
        return false;
      }

      if (this.excludeUpdateQuery()) {
        return false;
      }

      return (
        (!to.path.includes(from.path) && !from.path.includes(to.path)) ||
        to.query.adminStatus !== from.query.adminStatus ||
        to.query.type !== from.query.type ||
        to.query.feed !== from.query.feed ||
        to.query.feed_mode !== from.query.feed_mode ||
        to.query.content !== from.query.content
      );
    },
  },
  computed: {
    hasFeedComponent: function() {
      return this.$route.query.feed in components || this.$route.params.creator in components;
    },
    getFeedComponent: function() {
      return components[this.$route.query.feed] || components[this.$route.params.creator];
    },
  },
  watch: {
    $route(to, from) {
      if (this.mustBeUpdate(to, from)) {
        this.$store.state.posts.allStoriesLoaded = false;
        this.$store.state.posts.stories = {};

        if (Go.is(this.getPosts, "Function")) {
          this.getPosts();
        }
      }
    },
  },
};
