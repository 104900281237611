export default {
  wiki: () => import("@/components/wiki/Wiki.vue"),
  gallery: () => import("@/components/gallery/Gallery.vue"),
  tv: () => import("@/components/tv/Tv.vue"),
  watch: () => import("@/components/tv/Tv.vue"),
  reports: () => import("@/components/admin/AdminReports.vue"),
  "admin-subscriptions": () => import("@/components/admin/AdminSubscriptionsReport.vue"),
  "my-subscriptions": () => import("@/components/user/MySubscriptions.vue"),
  "my-subscribers": () => import("@/components/creator/MySubscribers.vue"),
  "my-notifications": () => import("@/components/user/MyNotifications.vue"),
  "my-credits": () => import("@/components/user/UserCredits.vue"),
  "my-profile": () => import("@/components/user/UserProfile.vue"),
  "my-transactions": () => import("@/components/user/MyTransactions.vue"),
  "my-credits-history": () => import("@/components/credits/MyCreditsHistory.vue"),
  "creator-statistics": () => import("@/components/creator/CreatorStatistics.vue"),
  "creator-statistics-subscriptions": () => import("@/components/creator/CreatorStatisticsSubs.vue"),
  "creator-statistics-content-details": () => import("@/components/creator/creatorStatisticsContentDetails.vue"),
  "admin-statistics-content-details": () => import("@/components/admin/AdminStatisticsContentDetails.vue"),
  "admin-susbcriptions-by-creator": () => import("@/components/admin/AdminSubscriptionsByCreators.vue"),
  "explore-creators": () => import("@/components/feed/LatestCreators.vue"),
  "favorites-creators": () => import("@/components/user/MyFavsCreators.vue"),
};
