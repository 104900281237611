<template>
  <main class="creator-feed">
    <section v-if="$user"><HomeFeed :postview="true" /></section>
    <section v-else><HomePublic /></section>
  </main>
</template>

<script>
import HomeFeed from "./HomeFeed.vue";
import HomePublic from "./HomePublic.vue";
export default {
  components: { HomeFeed, HomePublic },
};
</script>
